<template>
  <el-container>
    <el-main class="header">
      <el-header :class="{ sticky: isSticky, headerOpen: isActive }">
        <div class="logo" @click="toHome">
          <img src="../../assets/images/home/new-logo.svg" />
          <span>玄瑞科技</span>
        </div>
        <!-- <ul class="nav_menu" :class="{ open: isActive }" @click="to">
          <li :class="{ 'slide-in': isActive }" data-name="home" @click="toHome">
            首页
          </li>
          <li v-for="item in categoryAList" :key="item.category_id" :class="{ 'slide-in': isActive }"
            data-name="articles" @click="getArticlesList(item.category_id, item.category_name)">
            {{ item.category_name }}
          </li>
        </ul> -->
        <ul class="nav_menu" :class="{ open: isActive }" @click="to">
          <li :class="{ 'slide-in': isActive }" data-name="home">首页</li>
          <li :class="{ 'slide-in': isActive }" data-name="aboutUs">关于我们</li>
          <li v-for="item in categoryAList" :key="item.category_id" :class="{ 'slide-in': isActive }" data-name="articles"
            @click="getArticlesList(item.category_id, item.category_name, item.category_introduction)">
            {{ item.category_name }}
          </li>
        </ul>
        <div class="burger" :class="{ active: isActive }" @click="isActive = !isActive">
          <div class="top-line"></div>
          <div class="middle-line"></div>
          <div class="bottom-line"></div>
        </div>
      </el-header>
    </el-main>

    <el-main class="banner-box">
        <img src="../../assets/images/home/define_a_new_team.jpg">
    </el-main>

    <el-main class="articles-box">
      <div class="title">
        {{ categoryName }}
      </div>
      <span class="introduction">{{ categoryIntroduction }}</span>

      <ul class="articles-content-box">
        <li class="articles" v-for="item in formattedAArticleList" :key="item.article_id"
          @click="toArticle(item.article_id)">
          <div class="content-top">
            <div class="articles-img">
              <img :src="item.cover_img" />
            </div>
          </div>
          <div class="content-bottom">
            <div class="articles-title">
              {{ item.title }}
            </div>
            <div class="articles-introduction">
              {{ item.introduction }}
            </div>
            <div class="articles-date">
              <span>
                {{ item.pub_date }}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </el-main>

    <el-main class="page-box">
      <div class="page">
        <div class="left" @click="back">BACK</div>
        <span class="center">{{ currentPage }}/{{ pages }}</span>
        <div class="right" @click="next">NEXT</div>
      </div>
    </el-main>

    <div class="to-top" :class="{ show: isSticky }" @click="toTop">TOP</div>

    <el-footer>
      <!-- <div class="footer-top">
        <ul class="nav_menu" @click="to">
          <li data-name="home">首页</li>
          <li
            v-for="item in categoryAList"
            :key="item.category_id"
            data-name="articles"
            @click="getArticlesList(item.category_id, item.category_name)"
          >
            {{ item.category_name }}
          </li>
        </ul>
      </div> -->
      <div class="footer-bottom">
        <div class="footer-logo">
          <img src="../../assets/images/home/bottom-logo.svg" alt="logo" />
          <span>玄瑞科技</span>
        </div>
        <ul class="footer-content">
          <li>版权所有© 苏州玄瑞信息科技有限公司•版本: v2.22.5.29</li>
          <!-- <li>地址：苏州高新区青山路1号1幢303室</li> -->
          <li>苏公网安备32050602011432号•苏ICP备16047967号-1</li>
          <!-- <li>联系电话：</li> -->
        </ul>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import "swiper/css/swiper.min.css";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      isSticky: false,
      isActive: false,
    };
  },
  computed: {
    ...mapState("login", ["isUserLogin"]),
    ...mapState("home", ["categoryAList"]),
    ...mapState("articles", [
      "articleATotal",
      "articleAList",
      "categoryId",
      "categoryName",
      "categoryIntroduction",
      "currentPage",
      "pageSize",
    ]),
    ...mapGetters("articles", ["pages", "formattedAArticleList"]),
  },
  methods: {
    ...mapActions("articles", { getArticleA: "getArticleA" }),
    ...mapMutations("articles", {
      CHANGECATEGORYID: "CHANGECATEGORYID",
      CHANGECATEGORYNAME: "CHANGECATEGORYNAME",
      CHANGECURRENTPAGE: "CHANGECURRENTPAGE",
      CHANGECATEGORYINTRODUCTION: "CHANGECATEGORYINTRODUCTION"
    }),
    handleScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      setTimeout(() => {
        if (scrollTop > 0) {
          this.isSticky = true;
        } else {
          this.isSticky = false;
        }
      }, 0);
    },
    getArticlesList(categoryId, categoryName, categoryIntroduction) {
      this.isActive = false;
      this.CHANGECURRENTPAGE(1);
      // 获取文章列表
      // console.log(categoryId, categoryName);
      this.CHANGECATEGORYID(categoryId);
      this.CHANGECATEGORYNAME(categoryName);
      this.CHANGECATEGORYINTRODUCTION(categoryIntroduction);
      // console.log(categoryId);
      // console.log(this.$route.query.categoryId);
      this.getArticleA({
        currentPage: 1,
        pageSize: 10,
        articleId: 0,
        // categoryId: this.$route.query.categoryId,
        categoryId: this.categoryId,
      });
    },
    toHome() {
      this.$router.push({ path: "/home" });
      this.toTop();
    },
    toArticle(articleId) {
      this.$router.push("/article?articleId=" + articleId);
    },
    back() {
      if (this.currentPage !== 1) {
        let nowPage = this.currentPage - 1;
        this.CHANGECURRENTPAGE(nowPage);
        this.getArticleA({
          currentPage: this.currentPage,
          pageSize: 10,
          articleId: 0,
          categoryId: this.categoryId,
        });
      }
    },
    next() {
      if (this.currentPage !== this.pages) {
        let nowPage = this.currentPage + 1;
        this.CHANGECURRENTPAGE(nowPage);
        this.getArticleA({
          currentPage: this.currentPage,
          pageSize: 10,
          articleId: 0,
          categoryId: this.categoryId,
        });
      }
    },
    to(e) {
      // this.CHANGECURRENTPAGE(1);
      this.isActive = false;
      let name = e.target.dataset.name;
      let elname = e.target.tagName;
      if (
        elname === "LI" &&
        (name === "home" || name === "aboutUs" || name === "myuserinfo" || name == "login")
      ) {
        this.$router.push("/" + name);
      } else if (elname === "LI") {
        this.$router.replace("/" + name + "?categoryId=" + this.categoryId);
      }
      // setTimeout(() => {
      //   if (name === "category") {
      //     this.CHANGEISSHOW(false);
      //   }
      // }, 1);
      this.toTop();
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    // console.log("isUserLogin", this.isUserLogin);
    this.getArticleA({
      currentPage: 1,
      pageSize: 10,
      articleId: 0,
      categoryId: this.categoryId,
    });
    window.addEventListener("scroll", this.handleScroll);
  },
  updated() {
    if (this.categoryName) {
      document.title = "玄瑞科技 | " + this.categoryName;
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
body {
  color: #333;
  margin: 0;
  padding: 0;
  font-size: 16px;
  /* 隐藏滚动条 */
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

/* 隐藏滚动条 */
::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

.el-container {
  padding: 0;
  margin: 0;
  background: rgb(255, 255, 255);

  .header {
    // position: -webkit-sticky;
    // position: sticky;
    z-index: 999;
    position: fixed;
    top: 0;
    padding: 0;
    margin: 0;
    background: rgb(179, 190, 188);
    overflow: hidden;

    .el-header {
      // padding: 0;
      // margin: 0;
      // background: rgb(125, 178, 190);
      z-index: 9999;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px 5vw;
      color: #fff;
      background-image: linear-gradient(rgba(0, 0, 0, 1), transparent);
      transition: 0.6s;

      .logo {
        cursor: pointer;
        position: relative;
        max-height: 5vh;
        line-height: 5vh;
        // max-width: 5vh;
        font-weight: 500;
        color: #fff;
        font-size: 2em;
        text-transform: uppercase; // 字母小写转大写
        letter-spacing: 2px;
        transition: 0.6s;
        display: flex;
        flex: 1;

        img {
          // max-width: 5vh;
          max-height: 5vh;
        }
      }

      .nav_menu {
        position: relative;
        display: flex;
        justify-content: right;
        align-items: center;
        flex: 1;
        max-width: 1000px;

        li {
          cursor: pointer;
          position: relative;
          width: 100%;
          width: 90px;
          text-align: center;
          letter-spacing: 2px;
          font-weight: 500;
          transition: 0.3s;

          &:hover {
            opacity: 0.8;
            // color: rgb(1, 113, 138);
            color: rgb(73, 205, 252);
            // color: #333;
          }
        }
      }

      .burger {
        cursor: pointer;
        display: none;

        div {
          width: 25px;
          height: 4px;
          background: #fff;
          margin: 4px;
          transition: 0.3s ease-in-out 0.3s;
        }
      }

      .active {
        transform: translateX(0);

        div {
          transition: 0.3s ease-in-out 0.3s;
        }

        .top-line {
          transform: rotate(45deg) translate(5px, 6px);
        }

        .bottom-line {
          transform: rotate(-45deg) translate(5px, -6px);
        }

        .middle-line {
          opacity: 0;
          transform: translateX(50px);
          transition: 0.3s ease-in-out;
        }
      }

      @media screen and (max-width: 768px) {
        .logo {
          img {
            max-width: 16vh;
          }
        }

        .nav_menu {
          position: absolute;
          top: 80px;
          right: 0;
          bottom: 0;
          width: 50vw;
          height: calc(100vh);
          background: rgb(59, 59, 59);
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          transform: translateX(100%);
          transition: 0.4s ease-in-out;

          li {
            opacity: 1;
            height: 50px;
            line-height: 50px;
            width: 100%;
            transform: translateX(20px);
            // color: #333;
            opacity: 0;

            &:hover {
              opacity: 0.8;
              color: #333;
              background: rgb(230, 230, 230);
            }
          }
        }

        .burger {
          display: block;
        }

        .open {
          transform: translateX(0);
        }
      }
    }

    .headerOpen {
      background: #333;
      opacity: 0.8;
    }

    .swiper-box {
      transition: 0.6s;
      height: 0;
      // height: 100vh;
      overflow: hidden;
      width: 100vw;

      .swiper-container {
        width: 100vw;
        height: 100vh;
        margin: 0 auto;
        // --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
        --swiper-navigation-color: rgba(159,
            170,
            174,
            0.8);

        /* 单独设置按钮颜色 */
        // --swiper-navigation-size: 30px; /* 设置按钮大小 */
        .swiper-wrapper {
          cursor: pointer;
        }

        .swiper-button-prev,
        .swiper-button-next {
          transition: 0.3s;

          &:hover {
            --swiper-navigation-color: rgb(255, 255, 255);
          }
        }

        .swiper-pagination-bullet {
          width: 20px;
          height: 20px;
          background: #333;
          opacity: 0.8;
        }

        /deep/ .my-bullet-active {
          background: #ffffff;
          opacity: 1;
        }

        img {
          width: 100vw;
          height: 100vh;
          margin: 0 auto;
        }

        .swiper-title {
          position: absolute;
          top: 90vh;
          color: #fff;
          font-size: 24px;
          height: 10vh;
          width: 100vw;
          text-align: left;
          display: flex;
          justify-content: space-around;
          text-align: center;
          // align-items: center;
          // background: var(--swiper-navigation-color);
          background-image: linear-gradient(transparent, rgba(0, 0, 0, 1));
          // float: left;
          // z-index: 10;
        }
      }
    }

    .showbox {
      height: 100vh;
    }

    .sticky {
      height: 50px;
      padding: 5px 2vw;
      // background: #fff;
      // color: rgb(52, 52, 52);
      background: #fff;
      color: #222222;

      .logo {
        color: #222222;

        img {
          max-width: 5vh;
        }
      }

      .burger {
        div {
          background: #333;
        }
      }

      @media screen and (max-width: 768px) {
        .nav_menu {
          position: absolute;
          top: 80px;
          right: 0;
          bottom: 0;
          width: 50vw;
          height: calc(100vh);
          background: rgb(59, 59, 59);
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          transition: 0.4s ease-in-out;

          li {
            opacity: 1;
            height: 50px;
            width: 100%;
            line-height: 50px;
            color: #333;
            opacity: 0;

            &:hover {
              opacity: 0.8;
              color: #333;
              background: rgb(230, 230, 230);
            }
          }
        }

        .open {
          width: 40vw;
          top: 60px;
          background: #fff;
          opacity: 1;
        }
      }
    }
  }

  .banner-box {
    height: 65vh;
    overflow: hidden;
    text-align: center;
    padding: 0;

    img {
      height: 65vh;
      width: 100%;
    }
  }

  .articles-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20vh auto;
    min-height: 80vh;

    .articles-content-box {
      margin-top: 5vh;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      // .articles {
      //   cursor: pointer;
      //   overflow: hidden;
      //   display: flex;
      //   width: 90vw;
      //   height: 14vh;
      //   max-width: 1200px;
      //   // height: 12vh;
      //   background: rgb(253, 253, 253);
      //   border-bottom: 2px solid rgba(1, 113, 138, 0.6);
      //   margin: 10px;

      //   .content-left {
      //     .articles-title {
      //       padding: 10px;
      //       transform: translateY(-100%);
      //       color: #fff;
      //       background-image: linear-gradient(transparent, rgba(0, 0, 0, 1));
      //     }

      //     img {
      //       height: 12vh;
      //       width: 200px;
      //       padding: 10px;
      //     }
      //   }

      //   .content-right {
      //     width: 100%;
      //     display: flex;
      //     justify-content: space-between;
      //     padding: 10px;

      //     .articles-introduction {
      //       color: #333;
      //     }

      //     .articles-date {
      //       color: rgb(112, 112, 112);
      //       margin: 10vh 0 0 0;
      //     }
      //   }
      // }

      .articles {
        border-radius: 10px;
        // background: #222222;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        cursor: pointer;
        overflow: hidden;
        width: 340px;
        height: 350px;
        margin: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);

        .content-top {
          width: 340px;
          height: 180px;
          overflow: hidden;

          .articles-img {
            // background: #ffe96d;
            width: 340px;
            height: 180px;
            overflow: hidden;

            img {
              width: 340px;
              height: 180px;
            }
          }
        }

        .content-bottom {
          // background: #078923;
          height: 170px;

          // padding: 15px;
          .articles-title {
            white-space: normal;
            display: inline-block;
            overflow: hidden;
            padding: 0 15px;
            margin-top: 10px;
          }

          .articles-introduction {
            padding: 0 15px;
            -webkit-line-clamp: 2;
            line-height: 26px;
            color: #878787;
            white-space: normal;
            margin-top: 8px;
            font-size: 14px;
          }

          .articles-date {

            // background: #64a53f;
            span {
              // display: inline-block;
              display: block;
              float: right;
              background: #ffffff;
              border-radius: 13px;
              background: hsla(0, 0%, 60%, .15);
              min-width: 56px;
              color: #666;
              min-height: 26px;
              line-height: 26px;
              padding: 0 10px;
              text-align: center;
              font-size: 12px;
              margin: 10px 15px 0 0;
              box-sizing: border-box;
              text-decoration: none;
            }
          }
        }
      }
    }

    .title {
      // font-size: 4vh;
      margin-top: 10vh;
      font-size: 24px;
      margin-bottom: 10px;
      // border-bottom: 2px solid rgb(1, 113, 138);
    }

    .introduction {
      color: rgb(185, 185, 185);
      font-size: 14px;
    }

  }

  .page-box {
    display: flex;
    justify-content: center;

    .page {
      width: 100%;
      max-width: 1200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 0 4vw;
      text-align: center;
      height: 100%;

      div {
        width: 5vh;
        height: 5vh;
        // max-height: 50px;
        // max-width: 50px;
        line-height: 5vh;
        margin: 2vw;
        border-radius: 5px;
        // background: #fff;
        // color: #333;
        // box-shadow: 0 2px 100px #333;
        text-align: center;
        transition: 0.6s;
        cursor: pointer;
        // &:hover {
        //   background: #333;
        //   color: #fff;
        //   box-shadow: 0 0 2vh #333;
        // }
        box-shadow: 0 2px 5px rgb(1, 113, 138);
        border: 1px solid rgb(1, 113, 138);
        background: rgba(1, 113, 138, 0.6);
        color: #fff;

        &:hover {
          // background: #fff;
          // color: rgb(0, 0, 0);
          // color: #333;
          background: rgba(1, 113, 138, 1);
          box-shadow: 0 0 2vh rgb(1, 113, 138);
        }
      }

      .center {
        color: #333;
        cursor: default;
        display: block;
        width: 6vh;
        height: 6vh;
        line-height: 6vh;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  // .to-top {
  //   cursor: pointer;
  //   position: fixed;
  //   opacity: 0;
  //   width: 6vh;
  //   height: 6vh;
  //   line-height: 6vh;
  //   margin: 2vw;
  //   border-radius: 50%;
  //   text-align: center;
  //   right: 0;
  //   bottom: 0;
  //   z-index: 9999;
  //   transition: 0.6s;
  //   transform: translateY(10vh);
  //   // background: #fff;
  //   box-shadow: 0 2px 5px rgb(1, 113, 138);
  //   border: 1px solid rgb(1, 113, 138);
  //   background: rgba(138, 1, 1, 0.6);
  //   color: #fff;

  //   &:hover {
  //     // background: #fff;
  //     // color: rgb(0, 0, 0);
  //     // color: #333;
  //     background: rgba(138, 1, 1, 1);
  //     box-shadow: 0 0 2vh rgb(1, 113, 138);
  //   }
  // }
  .to-top {
    position: fixed;
    opacity: 0;
    width: 5vh;
    height: 5vh;
    line-height: 5vh;
    margin: 2vw;
    border-radius: 5%;
    box-shadow: 0 2px 5px rgb(1, 113, 138);
    border: 1px solid rgb(1, 113, 138);
    text-align: center;
    right: 0;
    bottom: 0;
    z-index: 9;
    transition: 0.6s;
    transform: translateY(10vh);
    // background: #fff;
    background: rgba(1, 113, 138, 0.6);
    color: #fff;
    cursor: pointer;

    &:hover {
      // background: #fff;
      // color: rgb(0, 0, 0);
      // color: #333;
      background: rgb(1, 113, 138);
      box-shadow: 0 0 2vh rgb(1, 113, 138);
    }
  }

  .show {
    opacity: 1;
    transform: translateY(0vh);
    display: block;
  }

  // .el-footer {
  //   padding: 0;
  //   margin: 0;
  //   background: rgb(185, 189, 153);
  //   height: 20vh !important;
  //   text-align: center;

  //   .footer-top {
  //     .nav_menu {
  //       display: flex;
  //       background: rgb(1, 113, 138);
  //       align-items: center;
  //       justify-content: center;
  //       height: 5vh;
  //       overflow: hidden;

  //       li {
  //         width: 80px;
  //         height: 5vh;
  //         line-height: 5vh;
  //         color: #fff;
  //         transition: 0.1s;
  //         cursor: pointer;

  //         &:hover {
  //           opacity: 0.8;
  //           color: #333;
  //           background: rgb(230, 230, 230);
  //         }
  //       }
  //     }
  //   }

  //   .footer-bottom {
  //     background: #302a29;
  //     color: #fff;

  //     .footer-logo {
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       height: 10vh;

  //       img {
  //         height: 5vh;
  //         cursor: pointer;
  //       }
  //     }

  //     .footer-content {
  //       display: flex;
  //       // background: #302a29;
  //       // background: rgb(1, 113, 138);
  //       align-items: center;
  //       justify-content: center;
  //       height: 5vh;
  //       overflow: hidden;

  //       li {
  //         margin: 10px;
  //         height: 5vh;
  //         line-height: 5vh;
  //         color: #fff;
  //         // color: #333;
  //         transition: 0.1s;
  //         cursor: pointer;

  //         &:hover {
  //           opacity: 0.8;
  //           // color: #333;
  //           color: #fff;
  //           // background: rgb(230, 230, 230);
  //         }
  //       }
  //     }
  //   }
  // }
  .el-footer {
    padding: 0;
    margin: 0;
    background: #222222;
    color: #8d8d8d;
    height: 20vh !important;
    // height: 300px !important;
    text-align: center;

    // .footer-top {
    //   .nav_menu {
    //     display: flex;
    //     background: rgb(1, 120, 138);
    //     align-items: center;
    //     justify-content: center;
    //     height: 5vh;
    //     overflow: hidden;
    //     li {
    //       width: 80px;
    //       height: 5vh;
    //       line-height: 5vh;
    //       color: #fff;
    //       transition: 0.1s;
    //       cursor: pointer;
    //       &:hover {
    //         opacity: 0.8;
    //         color: #222222;
    //         background: rgb(230, 230, 230);
    //       }
    //     }
    //   }
    // }
    .footer-bottom {
      background: #222222;
      color: #8d8d8d;

      .footer-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10vh;

        img {
          height: 5vh;
          cursor: pointer;
        }
      }

      .footer-content {
        display: flex;
        // background: #302a29;
        // background: rgb(1, 113, 138);
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        min-height: 5vh;
        overflow: hidden;

        li {
          margin: 10px;
          height: 5vh;
          line-height: 5vh;
          color: #8d8d8d;
          // color: #333;
          transition: 0.1s;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
            // color: #333;
            // color: #fff;
            // background: rgb(230, 230, 230);
          }
        }
      }
    }
  }

  // 定义一个动画: 导航li标签 滑入动画 (至少设置一个形参name)
  .slide-in (@name) {
    @keyframes @name {
      0% {
        transform: translateX(20px);
        opacity: 0;
      }

      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  // 调用动画 (传入实参name)
  .slide-in(slideIn);

  // 定义形参
  .animation(@animation-name, @animation-duration, @animation-timing-function, @animation-delay, @play-state, @animation-iteration-count) {
    -webkit-animation: @arguments;
    -moz-animation: @arguments;
    -ms-animation: @arguments;
    -o-animation: @arguments;
    animation: @arguments;
  }

  // 执行
  .slide-in {
    .animation(slideIn, 0.3s, linear, 0.5s, forwards, 1);
  }
}
</style>

